<template>
	<PageLayout :title="$t('dashboard.deposit')" :subtitle="$t('deposit.no-deposit-options')" />
</template>

<script>
import PageLayout from '@/components/page-layout/PageLayout';

export default {
	components: { PageLayout },
};
</script>
